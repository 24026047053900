/* eslint-disable @next/next/no-img-element */
"use client"
import { Button } from "@/app/components/ui/button"
import { Input } from "@/app/components/ui/input"
import { Label } from "@/app/components/ui/label"
import { HelpCircle, Loader2 } from "lucide-react"
import { useRouter } from "next/navigation"
import { useState } from "react"
import signInToAzure from "./signInToAzure"

export function PARSLoginBox({
  username_hint,
}: {
  readonly username_hint?: string
}) {
  let [isLoading, setIsLoading] = useState(false)
  let [username, setUsername] = useState(username_hint ?? "")
  /*   let [migrationNeeded, setMigrationNeeded] = useState(false) */
  let router = useRouter()

  return (
    <div className="w-full space-y-4 rounded bg-box-background p-6 shadow-lg dark:bg-box-background-dark">
      <h2 className="text-3xl font-bold">Sign in to PARS</h2>
      <div className="w-full space-y-2">
        <Label htmlFor="username">Username or Email</Label>
        <Input
          id="username"
          defaultValue={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <Button
        className="w-full"
        onClick={() => {
          if (username.length > 0) signInToAzure(setIsLoading, username)
        }}
        disabled={isLoading}
      >
        {isLoading ? <Loader2 className="w-6 animate-spin" /> : "Sign In"}
      </Button>
      <div className="flex flex-row space-x-6">
        <div className="mt-5">
          <HelpCircle className="size-8" />
        </div>
        <div className="text-sm">
          {
            "If you can't remember your username, you can enter your email address instead. To reset your password, enter your username or email and click Login, then click Forgot my password on the next page."
          }
        </div>
      </div>
    </div>
  )
}
